import inside from 'point-in-polygon';
import { serializeRestaurantSchedule } from './restaurantScheduleSerializer';

export class City {
  constructor(cityData = {}) {
    this.pk = cityData.pk;
    this.title = cityData.title;
    this.border = cityData.border;
    this.ownDeliveryAvailable = cityData.own_delivery_available;
    this.isReducedAreaActive = cityData.is_reduced_area_active;
    this.hotTariff = cityData.hot_tariff;
    this.paymentMethods = cityData.payment_methods;
    this.schedule = serializeRestaurantSchedule(cityData.schedule || []);
    this.service_fee = {
      amount: cityData.service_fee.amount ?? 0,
      title: cityData.service_fee.title ?? '',
      title_kz: cityData.service_fee.title_kz ?? '',
      description: cityData.service_fee.description ?? '',
      description_kz: cityData.service_fee.description_kz ?? '',
      is_enabled_for_vd: cityData.service_fee.is_enabled_for_vd ?? '',
      is_enabled_for_od: cityData.service_fee.is_enabled_for_od ?? '',
      is_dynamic_service_fee_active: cityData.service_fee.is_dynamic_service_fee_active ?? false,
      dynamic_service_fee_percent: cityData.service_fee.dynamic_service_fee_percent ?? 0,
      max_dynamic_service_fee_limit: cityData.service_fee.max_dynamic_service_fee_limit ?? 0,
      min_dynamic_service_fee_limit: cityData.service_fee.min_dynamic_service_fee_limit ?? 0,
    };
    this.limit_min_chocodelivery_time = cityData.limit_min_chocodelivery_time;
    this.limit_max_chocodelivery_time = cityData.limit_max_chocodelivery_time;
    this.negative_delivery_minutes_shift = cityData.negative_delivery_minutes_shift;
    this.positive_delivery_minutes_shift = cityData.positive_delivery_minutes_shift;
    this.show_time_from_chocodelivery = cityData.show_time_from_chocodelivery;
    this.isFreeDeliveryActive = cityData.is_free_delivery_active || false;
    this.freeDeliveryStartDt = cityData.free_delivery_start_dt || '';
    this.freeDeliveryEndDt = cityData.free_delivery_end_dt || '';
  }

  get mappedBorder() {
    return this.border.map(
      (latLong) => ([latLong.latitude, latLong.longitude]),
    );
  }

  containsPoint(lng, lat) {
    return inside([lat, lng], this.mappedBorder);
  }
}

export const serializeCity = (city) => new City(city);

export const serializeCityList = (cities) => cities.map(serializeCity);

export const serializeCityListShort = (cities) => cities
  .map((city) => ({ title: city.title, id: Number(city.pk) }));
