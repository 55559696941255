import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = {
  authServiceIsReady: false,
  token: null,
  deliveryTariffs: [],
  deliveryTimes: [],
  cities: [],
  citiesShort: [],
  menuOptions: [],
  allFoodsAndOptions: [],
  restaurantsFilter: {
    cityTitle: '',
    restaurantTitle: '',
    deliveryService: '',
    managerEmail: '',
    category_ids: '',
    restaurantState: '',
    hasHubs: '',
  },
  restaurantsCurrentPageOffset: 0,
};

export default {
  state,
  getters,
  mutations,
  actions,
};
