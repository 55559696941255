import { serializeDeliveryTime } from '@/serializers/deliveryTimeSerializer';
import { serializeDeliveryTariff } from '@/serializers/deliveryTariffSerializer';
import { getRadiusBySetOfH3Indexes } from '@/helpers/deliveryZone';

export class DeliveryAreaWithTariff {
  constructor(data = {}) {
    this.areaKeys = data.area_keys || [];
    this.deliveryTariff = data
      ? serializeDeliveryTariff(data.delivery_tariff) : null;
    this.deliveryTime = data
      ? serializeDeliveryTime(data.delivery_time) : null;
    this.isReducedArea = data.is_reduced_area || false;
    this.radius = data.area_keys
      ? getRadiusBySetOfH3Indexes(data.area_keys)
      : { outer: 0, inner: 0 };
    this.minSum = data.delivery_tariff?.conditions[0]?.order_min_cost || 2000;
    this.isSaved = true;
    this.isEdited = false;
  }

  get tariffTitle() {
    return `${this.deliveryTariff.title} (${this.deliveryTime.title})`;
  }
}

export const serializeDeliveryArea = (area) => new DeliveryAreaWithTariff(area);
export const serializeDeliveryAreaList = (area) => area.map(serializeDeliveryArea);
