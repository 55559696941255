import { serializeCoordinate } from '@/serializers/coordinateSerializer';

export class Location {
  constructor(coordinateData = {}) {
    this.coordinate = coordinateData
      ? serializeCoordinate(coordinateData.coordinate)
      : serializeCoordinate({ latitude: 0, longitude: 0 });
    this.text = coordinateData.text || undefined;
    this.commentRu = coordinateData.comment_ru || '';
    this.commentKz = coordinateData.comment_kz || '';
  }

  get location() {
    return [this.coordinate.latitude, this.coordinate.longitude, this.text];
  }

  get deserialized() {
    return {
      coordinate: this.coordinate,
      text: this.text,
      comment_ru: this.commentRu,
      comment_kz: this.commentKz,
    };
  }
}

export const serializeLocation = (location = {}) => new Location(location);
export const serializeLocationList = (locations = []) => locations.map(serializeLocation);

export const deserializeLocation = (toLocation) => {
  const location = new Location();
  Object.assign(location, toLocation);
  return location.deserialized;
};
