export class IntegrationSerializer {
  constructor(data = {}) {
    this.id = data.id || null;
    this.partnerRestaurant = {
      hermesId: data.hermes_restaurant_id || null,
      hermesName: data.hermes_restaurant_title || '',
    };
    this.iikoApiLogin = data.iiko_api_login || '';
    this.iikoMenuGroup = {
      id: data.iiko_menu_group_id || null,
      name: data.iiko_menu_group_title || '',
      nameAndId: data.iiko_menu_group_title ? `${data.iiko_menu_group_title} (${data.iiko_menu_group_id})` : 'Все меню',
    };
    this.externalMenuGroup = {
      id: data.iiko_menu_external_id || null,
      name: data.iiko_menu_external_title || '',
      nameAndId: data.iiko_menu_external_title
        ? `${data.iiko_menu_external_title} (${data.iiko_menu_external_id})`
        : 'Все меню',
    };
    this.externalPriceCategories = {
      id: data.iiko_price_category_id || null,
      name: data.iiko_price_category_title || '',
      nameAndId: data.iiko_price_category_title
        ? `${data.iiko_price_category_title} (${data.iiko_price_category_id})`
        : 'Все категории',

    };
    this.iikoOrderType = {
      id: data.iiko_order_type_id || null,
      name: data.iiko_order_type_title || '',
      nameAndId: `${data.iiko_order_type_title} (${data.iiko_order_type_id})`,
    };
    this.iikoOrganization = {
      id: data.iiko_organization_id || null,
      name: data.iiko_organization_title || '',
      nameAndId: `${data.iiko_organization_title} (${data.iiko_organization_id})`,
    };
    this.iikoPaymentType = {
      id: data.iiko_payment_type_id || null,
      name: data.iiko_payment_type_title || '',
      nameAndId: `${data.iiko_payment_type_title} (${data.iiko_payment_type_id})`,
    };
    this.iikoTerminal = {
      id: data.iiko_terminal_id || '',
      name: data.iiko_terminal_title || '',
      nameAndId: `${data.iiko_terminal_title} (${data.iiko_terminal_id})`,
    };
    this.partner = {
      id: data.partner_id || null,
      title: data.partner_title || '',
    };
    this.createdDt = data.created_dt || '';
    this.updatedDt = data.updated_dt || '';
    this.srCredentialId = data.sr_credential_id || null;
    this.srOrganizationId = data.sr_organization_id || null;
    this.srPosTerminalId = data.sr_pos_terminal_id || null;
    this.totalCount = data.total_count || null;
  }

  get deserialized() {
    const deserializedData = {
      iiko_api_login: this.iikoApiLogin,
      iiko_order_type_id: this.iikoOrderType.id,
      iiko_order_type_title: this.iikoOrderType.name,
      iiko_organization_id: this.iikoOrganization.id,
      iiko_organization_title: this.iikoOrganization.name,
      iiko_payment_type_id: this.iikoPaymentType.id,
      iiko_payment_type_title: this.iikoPaymentType.name,
      iiko_terminal_id: this.iikoTerminal.id,
      iiko_terminal_title: this.iikoTerminal.name,
      hermes_restaurant_id: this.partnerRestaurant.hermesId,
      hermes_restaurant_title: this.partnerRestaurant.hermesName,
      partner_id: this.partner.id,
      sr_credential_id: this.srCredentialId,
      sr_organization_id: this.srOrganizationId,
      sr_pos_terminal_id: this.srPosTerminalId,
    };
    if (this.iikoMenuGroup.id !== null) {
      deserializedData.iiko_menu_group_id = this.iikoMenuGroup.id;
      deserializedData.iiko_menu_group_title = this.iikoMenuGroup.name;
    }
    if (this.externalMenuGroup.id !== null && this.externalPriceCategories.id !== null) {
      deserializedData.iiko_menu_external_id = this.externalMenuGroup.id;
      deserializedData.iiko_menu_external_title = this.externalMenuGroup.name;
      deserializedData.iiko_price_category_id = this.externalPriceCategories.id;
      deserializedData.iiko_price_category_title = this.externalPriceCategories.name;
    }

    return deserializedData;
  }
}

export const serializeIntegration = (data = {}) => new IntegrationSerializer(data);
export const serializeIntegrationList = (data = []) => data.map(serializeIntegration);

export const deserializeIntegration = (toIntegration) => {
  const integration = new IntegrationSerializer();
  Object.assign(integration, toIntegration);
  return integration.deserialized;
};
